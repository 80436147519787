
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class OfferCardDiscountLabel extends Vue {
  @Prop({ type: Number, default: 0 })
  private readonly percents!: number

  @Prop({ type: Number, default: 0 })
  private readonly discountDayLeft!: number

  @Prop({ type: String, default: 'md' })
  private readonly size!: 'sm' | 'md'
}
