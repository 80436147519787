
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import IconStar from '~/assets/icons/icon-star.svg?inline'
import { AppProviderRatingProps } from '~/components/AppProviderRating/types'

import type { Theme } from '~/types/theme'

@Component({
  components: {
    IconStar,
  },
})
export default class AppProviderRating extends Vue {
  @Prop({
    required: true,
    type: Number,
  })
  providerId!: AppProviderRatingProps['providerId']

  @Prop({
    required: false,
    validator: (value: any) => {
      return value === null || !isNaN(parseFloat(value))
    },
  })
  rating!: AppProviderRatingProps['rating']

  @Prop({
    type: String,
    required: true,
  })
  readonly logoUrl!: AppProviderRatingProps['logoUrl']

  @Prop({
    type: String,
    required: true,
  })
  readonly providerName!: AppProviderRatingProps['providerName']

  @Prop({ type: Number, required: false })
  readonly reviews!: AppProviderRatingProps['reviews']

  @Prop({ type: String, required: false, default: 'row' })
  readonly direction!: AppProviderRatingProps['direction']

  @Prop({ type: Boolean, required: false })
  readonly withBackground!: boolean

  @Prop({ type: String, required: false, default: 'light' })
  readonly theme!: Theme

  get filledStarCount (): number {
    return Math.ceil(Number(this.rating))
  }
}
